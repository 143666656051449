<template>
	<div>
		<LoadingProcess v-if="loadingBar"></LoadingProcess>
		<div class="row">
			<div class="col-md-4">
				<b>Du</b>
				<datetime type="date" :placeholder="$t('TEXT_ALL.DATE_START')" v-model="dateStart" input-class="form-control  font-weight-bold pl-2" :phrases="{ok: $t('TEXT_ALL.OK'), cancel: $t('TEXT_ALL.CANCEL')}" :week-start="7" auto></datetime>
				<b>au</b>
				<datetime type="date" :placeholder="$t('TEXT_ALL.DATE_START')" v-model="dateEnd" input-class="form-control  font-weight-bold pl-2" :phrases="{ok: $t('TEXT_ALL.OK'), cancel: $t('TEXT_ALL.CANCEL')}" :week-start="7" auto></datetime>
			</div>
			<div class="col-md-4">
				<b>Heure de réference</b>
				<datetime type="time" :placeholder="$t('TEXT_ALL.DATE_START')" v-model="Time" input-class="form-control  font-weight-bold pl-2" :phrases="{ok: $t('TEXT_ALL.OK'), cancel: $t('TEXT_ALL.CANCEL')}" :week-start="7" auto></datetime>
				<b>Tolérance</b>
				<select class="form-control" v-model="ToleranceTime"  placeholder="">
					<option selected disabled value="" >Tolérance</option>
					<option value="1">1</option>
					<option value="2">2</option>
					<option value="3">3</option>
					<option value="4">4</option>
					<option value="5">5</option>
					<option value="6">6</option>
				</select>
			</div>
			<div class="col-md-4">
				<b>Voitures</b>
				<treeselect v-model="CarsSelect" :multiple="false" :options="options"/>
				<br>
				<button  style="float: inline-end;" type="submit" class="btn btn-success font-weight-bold btn-hover-light-primary mt-3 mt-sm-0 px-7" @click="generateReport()"> Appliquer </button>
			</div>
		</div>
		<b-card class="mt-10 mb-10" border-variant="primary" header="" header-bg-variant="primary" header-text-variant="white" align="left" >
			<b-card-text>
				<div class="row " >

					<div class="col-md-6">
						<div class="row">
							<div class="col-md-6"><b>Voitures</b></div>
							<div class="col-md-6"><span>{{carsShow}}</span></div>
						</div>
						<div class="row">
							<div class="col-md-6"><b>Date de début</b></div>
							<div class="col-md-6"><span>{{dateStart}}</span></div>
						</div>
						<div class="row">
							<div class="col-md-6"><b>Date de fin</b></div>
							<div class="col-md-6"><span>{{dateEnd}}</span></div>
						</div>
						
					</div>
					<div class="col-md-6">
						<div class="row">
							<div class="col-md-6"><b>Heure de réference</b></div>
							<div class="col-md-6"><span>{{new Date(Time).getHours()}}</span></div>
						</div>
						<div class="row">
							<div class="col-md-6"><b>Tolérance</b></div>
							<div class="col-md-6"><span>{{ToleranceTime}}</span></div>
						</div>
						
					</div>
				</div>
			</b-card-text>
		</b-card>
		<hr>
		<div class="content mt-5">
				<div style="float: inline-end;" >
					<button @click="GetRapport()" type="button" class="btn btn-fh btn-light btn-hover-primary font-weight-bold px-2 px-lg-5 mr-2">
						<span class="svg-icon svg-icon-primary svg-icon-lg">
							<inline-svg src="media/svg/icons/Files/File.svg" />
						</span>
						PDF
					</button>
				</div>
				<table class="table table-striped">
					<thead>
						<tr>
							
							<th scope="col">Date</th>
							<th scope="col">Position</th>
							<th scope="col">status</th>
						</tr>
					</thead>
					<tbody  v-for="(value, index) in RapportFirststartFleet"  :key="index">
						<tr v-for="(data, index1) in value" :key="index1">
							<td>{{data.date != "-" ? moment(parseFloat(data.date)).format('Do MMMM YYYY, HH:mm:ss') : data.date}}</td>
							<td>
								<a target="_blank"  :href="'https://www.google.com/maps/dir/' + data.lat + ',' + data.lon" v-html="data.adr" >
									{{data.adr}}
								</a>
							</td>
							<td>{{data.verif == "in" ? "Ok" : "Vérifier"}}</td>
						</tr>
					</tbody>
				</table>
		</div>
		 <VueHtml2pdf
			:show-layout="false"
			:float-layout="true"
			:enable-download="true"
			:preview-modal="false"
			:paginate-elements-by-height="1400"
			filename="Trajets"
			:pdf-quality="2"
			:manual-pagination="true"
			pdf-format="a4"
			pdf-orientation="landscape"
			pdf-content-width="1120px"
			ref="html2Pdf"
			margin = "1"
		>				
		<section slot="pdf-content" class="">
			<div class="row" >
				<div class="col-md-6">
					<img src="media/tlmtk/sayartechpro-min.png" class="max-h-30px"/> 
				</div>
				<div class="col-md-6">
					<b class="float-right">{{moment().format('Do MMMM YYYY, HH:mm:ss')}}</b>
				</div>
			</div>	
			<b-card class="mt-10 mb-10" border-variant="primary" header="" header-bg-variant="primary" header-text-variant="white" align="left" >
				<b-card-text>
					<div class="row " >

						<div class="col-md-6">
							<div class="row">
								<div class="col-md-6"><b>Voitures</b></div>
								<div class="col-md-6"><span>{{carsShow}}</span></div>
							</div>
							<div class="row">
								<div class="col-md-6"><b>Date de début</b></div>
								<div class="col-md-6"><span>{{dateStart}}</span></div>
							</div>
							<div class="row">
								<div class="col-md-6"><b>Date de fin</b></div>
								<div class="col-md-6"><span>{{dateEnd}}</span></div>
							</div>
							
						</div>
						<div class="col-md-6">
							<div class="row">
								<div class="col-md-6"><b>Heure de réference</b></div>
								<div class="col-md-6"><span>{{new Date(Time).getHours()}}</span></div>
							</div>
							<div class="row">
								<div class="col-md-6"><b>Tolérance</b></div>
								<div class="col-md-6"><span>{{ToleranceTime}}</span></div>
							</div>
							
						</div>
					</div>
				</b-card-text>
			</b-card>
			<table class="table table-striped">
					<thead>
						<tr>
							<th scope="col">Date</th>
							<th scope="col">Position</th>
							<th scope="col">status</th>
						</tr>
					</thead>
					<tbody  v-for="(value, index) in RapportFirststartFleet"  :key="index">
						<tr v-for="(data, index1) in value" :key="index1">
							<td>{{data.date != "-" ? moment(parseFloat(data.date)).format('Do MMMM YYYY, HH:mm:ss') : data.date}}</td>
							<td>
								<a target="_blank"  :href="'https://www.google.com/maps/dir/' + data.lat + ',' + data.lon" v-html="data.adr" >
									{{data.adr}}
								</a>
							</td>
							<td>{{data.verif == "in" ? "Ok" : "Vérifier"}}</td>
						</tr>
					</tbody>
				</table>
		</section>
		</VueHtml2pdf>
	</div>
</template>

<script>
import Cookie from "@/core/services/localstorage.module";
//import functionAssets from "@/core/services/function.assets.module.js";
import propertiesCar from "@/core/services/properties.car.module.js";
import axios from "axios";
import Treeselect from '@riophae/vue-treeselect'


import VueHtml2pdf from 'vue-html2pdf'

import { Datetime } from 'vue-datetime'
// You need a specific loader for CSS files
import 'vue-datetime/dist/vue-datetime.css'
/*import { Settings } from 'luxon'
Settings.defaultLocale = 'fr'
Settings.defaultZone = "America/Bogus"
console.log(Settings);
Settings.DateTime.fromJSDate(new Date())
Datetime.set({ outputCalendar: 'beng', zone: 'utc' })
*/
import moment from 'moment'; 
moment.locale(Cookie.GetCookie('language'));
moment.updateLocale('fr', {
    relativeTime : {
        past: function(input) {
          return input === "à l'instant"
            ? input
            : 'depuis '+input
        },
        s  : "à l'instant",
        future: "dans %s",
        ss : '%d secondes',
        m:  "une minute",
        mm: "%d minutes",
        h:  "une heure",
        hh: "%d heures",
        d:  "un jour",
        dd: "%d jours",
        M:  "un mois",
        MM: "%d mois",
        y:  "une année",
        yy: "%d années"
    }
});

import LoadingProcess from "@/view/pages/Loading-process.vue";
export default {
	components: {
		VueHtml2pdf,Treeselect,datetime: Datetime,LoadingProcess
    },
	data: () => ({
		ToleranceTime :"1",
		carsShow :"",
	
	
		itemsData : [],
		propertiesCar:propertiesCar,
		mindata : [],
		mindataShow : [],
		mindataShowGp : [],
		CarsSelect: [],
		options: [],
		newDate : new Date().toJSON(),
		dateStart : "",
		dateEnd : "",
		RapportFirststartFleet:{},
		moment:moment,
		loadingBar : false
    }),
	destroyed() {
		this.$destroy() 
		//clearInterval(this.TimeReloadDataSettings);
	},
	computed: {
		
	},
	
	mounted() {
		//moment:moment()
		axios.defaults.headers["Content-Type"] =  "application/json";
		axios.defaults.withCredentials = true;
		axios.defaults.baseURL = "https://app.sayarte.ch/api/";
		this.dateStart = new Date().toJSON();
		this.dateEnd = new Date().toJSON();
		this.Time = new Date().toJSON();
		this.GetCarsList();
		
	},
	created() {

    },
	methods: {

		generateReport () {
			this.carsShow = propertiesCar.GetNameCarsById(this.CarsSelect)
			if(this.CarsSelect.length == 0){
				alert("Veuillez sélectionner un véhicule");
				return;
			}
			var dateStart = new Date(this.dateStart).getTime();
			var dateEnd = new Date(this.dateEnd).getTime();
			var from = new Date(this.Time).getHours();
			
			//var ids = propertiesCar.GetAllCarGpById(this.CarsSelect);
			this.loadingBar = true;
			axios.post("controleur/rappoting/reporting.php",{which: "first_start_on_car",id:this.CarsSelect/*ids.join(",")*/,'while':this.ToleranceTime,pdf:false,excel:false,start:dateStart,end:dateEnd,from:from}).then((response) => {
				this.RapportFirststartFleet = response.data;
				this.loadingBar = false;
				
			}).catch((error) => {    
				if (error.response) {
					if (error.response.status == 500) {
						Cookie.DeleteCookie();
						this.loadingBar = false;
						this.$router.push('/login');
					}
				}
			});
        },
		GetCarsList () {
			this.options = propertiesCar.SelectBoxFormat();
		},
		GetRapport () {
			this.$refs.html2Pdf.generatePdf();

		}

	},
}
</script>
<template>
	<div>
		<LoadingProcess v-if="loadingBar"></LoadingProcess>
		
							<!--begin::Header-->
					<!--<div class="card-header py-3">
						<div class="card-title align-items-start flex-column">
							<h3 class="card-label font-weight-bolder text-dark">Rapports Opérationnels - Flotte</h3>
							<span class="text-muted font-weight-bold font-size-sm mt-1">Update your personal informaiton</span>
						</div>
						<div class="card-toolbar">
							<button type="reset" @click="generateReport()" class="btn btn-success mr-2">Save Changes</button>
							<button type="reset" class="btn btn-secondary">Cancel</button>
						</div>
						<a href="#" class="btn btn-primary btn-fixed-height font-weight-bold px-2 px-lg-5 mr-2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											<span class="d-none d-md-inline">New Report</span>
										</a>
					</div>-->
						<div class="row">
							<div class="col-md-4">
								<treeselect v-model="CarsSelect" :multiple="true" :options="options"/>
							</div>
							<div class="col-md-3">
								<datetime type="date" :placeholder="$t('TEXT_ALL.DATE_START')" v-model="dateStart" input-class="form-control  font-weight-bold pl-2" :phrases="{ok: $t('TEXT_ALL.OK'), cancel: $t('TEXT_ALL.CANCEL')}" :week-start="7" auto></datetime>
							</div>
							<div class="col-md-3">
								<datetime type="date" :placeholder="$t('TEXT_ALL.DATE_END')" v-model="dateEnd" input-class="form-control  font-weight-bold pl-2" :phrases="{ok: $t('TEXT_ALL.OK'), cancel: $t('TEXT_ALL.CANCEL')}" :week-start="7" auto></datetime>
							</div>
							<div class="col-md-2">
								<button type="submit" class="btn btn-success font-weight-bold btn-hover-light-primary mt-3 mt-sm-0 px-7" @click="generateReport()"> Appliquer </button>
							</div>
							<!--<div class="col-xl-6 col-md-12 col-xs-12 col-sm-12" style="padding: 0;">
								<div class="d-flex align-items-md-center flex-column flex-md-row justify-content-end">
									<div class="bg-white rounded p-4 d-flex flex-grow-1 flex-sm-grow-0">
										<div class="form d-flex align-items-md-center flex-sm-row flex-column flex-grow-1 flex-sm-grow-0">
											<div class="d-flex align-items-center py-3 py-sm-0 px-sm-3">
												
											</div>
											<span class="bullet bullet-ver h-25px d-none d-sm-flex mr-2"></span>
											<div class="d-flex align-items-center py-3 py-sm-0 px-sm-3">
												
											</div>
											<span class="bullet bullet-ver h-25px d-none d-sm-flex mr-2"></span>
											<button type="submit" class="btn btn-dark font-weight-bold btn-hover-light-primary mt-3 mt-sm-0 px-7" @click="generateReport()"> OK </button>
											<button type="submit" class="btn btn-dark font-weight-bold btn-hover-light-primary mt-3 mt-sm-0 px-7" @click="GetRapport()"> PDF </button>
										</div>
									</div>
								</div>
							</div>-->
						</div>
					<div class="content">
						
						
							<b-card class="mt-10 mb-10" border-variant="primary" header="" header-bg-variant="primary" header-text-variant="white" align="left" >
								<b-card-text>
									<div class="row " >

										<div class="col-md-6">
											<div class="row">
												<div class="col-md-6"><b>Date de début</b></div>
												<div class="col-md-6"><span>{{dateStartReporting}}</span></div>
											</div>
											<div class="row">
												<div class="col-md-6"><b>Date de fin</b></div>
												<div class="col-md-6"><span>{{dateEndReporting}}</span></div>
											</div>
											<div class="row">
												<div class="col-md-6"><b>Nombre de Véhicule</b></div>
												<div class="col-md-6"><span>{{TotalCars}}</span></div>
											</div>
										</div>
										<div class="col-md-6">
											<div class="row">
												<div class="col-md-6"><b>Distance parcourue (Km)</b></div>
												<div class="col-md-6"><span>{{TotalDistance}}</span></div>
											</div>
											<!--<div class="row">
												<div class="col-md-6"><b>Durée de fonctionnement</b></div>
												<div class="col-md-6"><span>{{TotalEnginOn}}</span></div>
											</div>-->
											<div class="row">
												<div class="col-md-6"><b>Totale des arrêts</b></div>
												<div class="col-md-6"><span>{{TotalStops}}</span></div>
											</div>
											
										</div>
									</div>
								</b-card-text>
							</b-card>
							<div style="float: inline-end;" v-if="TotalCars != 0">
								<button @click="GetRapport()" type="button" class="btn btn-fh btn-light btn-hover-primary font-weight-bold px-2 px-lg-5 mr-2">
									<span class="svg-icon svg-icon-primary svg-icon-lg">
										<inline-svg src="media/svg/icons/Files/File.svg" />
									</span>
									PDF
								</button>
							</div>
							<table class="table table-striped">
								<thead>
									<tr>
										<th scope="col">Véhicule</th>
										<th scope="col">Durée de fonctionnement</th>
										<th scope="col">Ralenti</th>
										<th scope="col">Distance(Km)</th>
										<th scope="col">V. max</th>
										<th scope="col">Arrêts</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="data in generalRapportData" :key="data.idcar">
										<th scope="row">{{propertiesCar.GetNameCarsById(data.idcar)}}</th>
										<td>{{propertiesCar.ConvertTimeHeure(parseFloat(data.ignitionRange)/1000)}}</td>
										<td>{{propertiesCar.ConvertTimeHeure(parseFloat(data.idleRange)/1000)}}</td>
										<td>{{parseInt(parseFloat(data.distanceTraveled)/1000)}}</td>
										<td>{{parseInt(parseFloat(data.maxSpeed))}} Km/h</td>
										<td>{{data.ignitionStops}}</td>
									</tr>
								</tbody>
							</table>
						</div>
					 <VueHtml2pdf
						:show-layout="false"
						:float-layout="true"
						:enable-download="true"
						:preview-modal="false"
						:paginate-elements-by-height="1400"
						filename="Trajets"
						:pdf-quality="2"
						:manual-pagination="true"
						pdf-format="a4"
						pdf-orientation="landscape"
						pdf-content-width="1120px"
						ref="html2Pdf"
						margin = "1"
					>				
					<section slot="pdf-content" class="m-10">
						<div class="row" >
							<div class="col-md-6">
								<img src="media/tlmtk/sayartechpro-min.png" class="max-h-30px"/> 
							</div>
							<div class="col-md-6">
								<b class="float-right">{{moment.format('Do MMMM YYYY, HH:mm:ss')}}</b>
							</div>
						</div>	
						<b-card class="mt-10 mb-10" border-variant="primary" header="" header-bg-variant="primary" header-text-variant="white" align="left" >
							
							<b-card-text>
									<div class="row" >

										<div class="col-md-6">
											<div class="row">
												<div class="col-md-6"><b>Date de début</b></div>
												<div class="col-md-6"><span>{{dateStartReporting}}</span></div>
											</div>
											<div class="row">
												<div class="col-md-6"><b>Date de fin</b></div>
												<div class="col-md-6"><span>{{dateEndReporting}}</span></div>
											</div>
											<div class="row">
												<div class="col-md-6"><b>Nombre de Véhicule</b></div>
												<div class="col-md-6"><span>{{generalRapportData.length}}</span></div>
											</div>
										</div>
										<div class="col-md-6">
											<div class="row">
												<div class="col-md-6"><b>Distance parcourue (Km)</b></div>
												<div class="col-md-6"><span>{{TotalDistance}}</span></div>
											</div>
											<!--<div class="row">
												<div class="col-md-6"><b>Durée de fonctionnement</b></div>
												<div class="col-md-6"><span>{{TotalEnginOn}}</span></div>
											</div>-->
											<div class="row">
												<div class="col-md-6"><b>Totale des arrêts</b></div>
												<div class="col-md-6"><span>{{TotalStops}}</span></div>
											</div>
											
										</div>
									</div>
								</b-card-text>
							</b-card>
							
					
							<table class="table table-striped">
								<thead>
									<tr>
										<th scope="col">Véhicule</th>
										<th scope="col">Durée de fonctionnement</th>
										<th scope="col">Ralenti</th>
										<th scope="col">Distance(Km)</th>
										<th scope="col">V. max</th>
										<th scope="col">Arrêts</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="data in generalRapportData" :key="data.idcar">
										<th scope="row">{{propertiesCar.GetNameCarsById(data.idcar)}}</th>
										<td>{{propertiesCar.ConvertTimeHeure(parseFloat(data.ignitionRange)/1000)}}</td>
										<td>{{propertiesCar.ConvertTimeHeure(parseFloat(data.idleRange)/1000)}}</td>
										<td>{{parseInt(parseFloat(data.distanceTraveled)/1000)}}</td>
										<td>{{parseInt(parseFloat(data.maxSpeed))}} Km/h</td>
										<td>{{data.ignitionStops}}</td>
									</tr>
								</tbody>
							</table>
					</section>
					</VueHtml2pdf>
					
					<!--end::Form-->

	</div>
</template>

<script>
import Cookie from "@/core/services/localstorage.module";
//import functionAssets from "@/core/services/function.assets.module.js";
import propertiesCar from "@/core/services/properties.car.module.js";
import axios from "axios";
import Treeselect from '@riophae/vue-treeselect'


import VueHtml2pdf from 'vue-html2pdf'

import { Datetime } from 'vue-datetime'
// You need a specific loader for CSS files
import 'vue-datetime/dist/vue-datetime.css'
/*import { Settings } from 'luxon'
Settings.defaultLocale = 'fr'
Settings.defaultZone = "America/Bogus"
console.log(Settings);
Settings.DateTime.fromJSDate(new Date())
Datetime.set({ outputCalendar: 'beng', zone: 'utc' })
*/
import moment from 'moment'; 
moment.locale(Cookie.GetCookie('language'));
moment.updateLocale('fr', {
    relativeTime : {
        past: function(input) {
          return input === "à l'instant"
            ? input
            : 'depuis '+input
        },
        s  : "à l'instant",
        future: "dans %s",
        ss : '%d secondes',
        m:  "une minute",
        mm: "%d minutes",
        h:  "une heure",
        hh: "%d heures",
        d:  "un jour",
        dd: "%d jours",
        M:  "un mois",
        MM: "%d mois",
        y:  "une année",
        yy: "%d années"
    }
});

import LoadingProcess from "@/view/pages/Loading-process.vue";
export default {
	components: {
		VueHtml2pdf,Treeselect,datetime: Datetime,LoadingProcess
    },
	data: () => ({
		itemsData : [],
		propertiesCar:propertiesCar,
		mindata : [],
		mindataShow : [],
		mindataShowGp : [],
		CarsSelect: [],
		options: [],
		newDate : new Date().toJSON(),
		dateStart : "",
		dateEnd : "",
		generalRapportData:{},
		dateStartReporting: 0,
		dateEndReporting: 0,
		TotalCars: 0,
		TotalDistance: 0,
		TotalEnginOn: 0,
		TotalStops: 0,
		moment:moment(),
		loadingBar : false
    }),
	destroyed() {
		this.$destroy() 
		//clearInterval(this.TimeReloadDataSettings);
	},
	computed: {
		
	},
	
	mounted() {
		//moment:moment()
		axios.defaults.headers["Content-Type"] =  "application/json";
		axios.defaults.withCredentials = true;
		axios.defaults.baseURL = "https://app.sayarte.ch/api/";
		this.dateStart = new Date().toJSON();
		this.dateEnd = new Date().toJSON();
		this.GetCarsList();
	},
	created() {

    },
	methods: {

		generateReport () {
            if(this.dateStart == "" || this.dateEnd =="") {
				alert("data start and end ");
				return;
			}
			this.TotalDistance = 0;
			this.TotalStops = 0;
			this.TotalCars = 0

			this.dateStartReporting = moment(this.dateStart).format('Do MMMM YYYY, HH:mm:ss');
			this.dateEndReporting = moment(this.dateEnd).format('Do MMMM YYYY, HH:mm:ss');;
			var dateStart = new Date(this.dateStart).getTime();
			var dateEnd = new Date(this.dateEnd).getTime();
			if(dateStart > dateEnd) {
				alert("data start > date end");
				return;
			}
			this.loadingBar = true;
			var ids = propertiesCar.GetAllCarGpById(this.CarsSelect);
			axios.get("controleur/core/calldataV2.php",{params: {ids: ids.join(","),start:dateStart,end:dateEnd,which: "generalreport"}}).then((response) => {
				this.generalRapportData = response.data.data;
				this.TotalCars = this.generalRapportData.length;
				for(var res in this.generalRapportData){
					console.log(this.generalRapportData[res]);
					this.TotalDistance = this.TotalDistance + parseInt(parseFloat(this.generalRapportData[res].distanceTraveled)/1000);
					this.TotalStops = this.TotalStops + parseInt(this.generalRapportData[res].ignitionStops);
					this.loadingBar = false;
				}
			}).catch((error) => {    
				if (error.response) {
					if (error.response.status == 500) {
						Cookie.DeleteCookie();
						this.loadingBar = false;
						this.$router.push('/login');
					}
				}
			});
			
        },
		GetCarsList () {
			this.options = propertiesCar.SelectBoxFormat();
		},
		GetRapport () {
			this.$refs.html2Pdf.generatePdf();

		}

	},
}
</script>
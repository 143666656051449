<template>
	<div>
		<LoadingProcess v-if="loadingBar"></LoadingProcess>
		<b-card class="mt-10 mb-10" border-variant="primary" header="" header-bg-variant="primary" header-text-variant="white" align="left" >
			<div class="row">
				<!--<div class="col-md-4">
					<treeselect v-model="CarsSelect" :multiple="false" :options="options"/>
				</div>-->
				<div class="col-md-4">
					<datetime type="datetime" :placeholder="$t('TEXT_ALL.DATE_START')" v-model="dateStart" input-class="form-control  font-weight-bold pl-2" :phrases="{ok: $t('TEXT_ALL.OK'), cancel: $t('TEXT_ALL.CANCEL')}" :week-start="7" auto></datetime>
				</div>
				<div class="col-md-3">
					<select class="form-control" v-model="AddRemouve">
						<option value="+" selected>+</option>
						<option value="-">-</option>
					</select>
				</div>
				<div class="col-md-3">
					<select class="form-control" v-model="ToleranceTime">
						<option value="15" selected>15 Min</option>
						<option value="30">30 Min</option>
						<option value="45">45 Min</option>
						<option value="1">1 heure</option>
					</select>
				</div>
				<div class="col-md-2">
					<button type="submit" class="btn btn-success font-weight-bold btn-hover-light-primary mt-3 mt-sm-0 px-7" @click="UpdateDataCars();"> Appliquer </button>
				</div>
			</div>
		</b-card>
		<div class="row">
			<div class="col-md-10">
			</div>
			<div class="col-md-1">
				<button type="button" class="btn btn-outline-primary" @click="ChangeVue('maps')">Maps</button>
			</div>
			<div class="col-md-1">
				<button type="button" class="btn btn-outline-info" @click="ChangeVue('table')">Table</button>
			</div>
		</div>
		<hr>
		<div  style="padding: 0;" v-if="MapsOrTable=='table'">
			<b-table show-empty small stacked="md" :items="geoJSONArray" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
				<template v-slot:cell(vehicle)="row">
					<b :style="{color:row.item.color}">{{ row.item.name }}</b>
				</template>
				<template v-slot:cell(timestart)="row">
					{{moment(parseFloat(row.item.time[0])).format("DD/MM/YYYY hh:mm:ss")}}
				</template>
				<template v-slot:cell(timeend)="row">
					{{moment(parseFloat( row.item.time[row.item.time.length-1])).format("DD/MM/YYYY hh:mm:ss")}}
				</template>
				<template v-slot:cell(timetrip)="row">
					{{ConvertTime(parseFloat(row.item.time[row.item.time.length-1]) - parseFloat(row.item.time[0]))}}
				</template>
				<template #cell(show_details)="row">
					<b-button size="sm" @click="row.toggleDetails" class="mr-2">
						{{ row.detailsShowing ? 'Cacher' : 'Afficher'}} map
					</b-button>
				</template>
				<template #row-details="row">
					<b-card >
						<l-map style="height: 300px; width: 100%" zoom="12" :center="[row.item.coordinates[0][0],row.item.coordinates[0][1]]"	>
							<l-polyline weight="10" :lat-lngs="row.item.coordinates" :color="row.item.color"></l-polyline>
							
							<v-rotated-marker v-if="row.item.coordinates[0].length != 0" 
								ref="marker"
								:lat-lng="[row.item.coordinates[0][0],row.item.coordinates[0][1]]" 
								:icon="icon_start" 
								:rotationAngle="0">
							</v-rotated-marker>
							<v-rotated-marker  v-if="row.item.coordinates[0].length != 0" 
								ref="marker"
								:lat-lng="[row.item.coordinates[row.item.coordinates.length-1][0],row.item.coordinates[row.item.coordinates.length-1][1]]" 
								:icon="icon_stop" 
								:rotationAngle="0">
							</v-rotated-marker>
							<l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
						</l-map>
					  <!--<b-button size="sm" @click="row.toggleDetails">Cacher</b-button>-->
					</b-card>
				</template>
			</b-table>
		</div>
		<div  style="padding: 0;"  v-if="MapsOrTable=='maps'">
			<!--<div id="map" style="height: 300px;"></div>-->
			<l-map id="maps" v-model="maps" ref="maps" v-bind:style="{width: '100%',height: Height}" :zoom="zoom"  :center="center" @update:zoom="zoomUpdated" @update:center="centerUpdated" @update:bounds="boundsUpdated">
				<l-popup content="polygonPopUp"></l-popup>
				<l-polyline  v-for="(item, index) in geoJSON"  :key="index" :lat-lngs="item" :color="item.color" @mouseup="nodeClick($event, index,item)"></l-polyline>
				<l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>

				
			</l-map>
		</div>
	</div>
</template>

<script>
import Cookie from "@/core/services/localstorage.module";
//import functionAssets from "@/core/services/function.assets.module.js";
//import propertiesCar from "@/core/services/properties.car.module.js";
import axios from "axios";
import { LMap, LTileLayer/*,LPopup,LControl ,LCircle*/,LPolyline,LPopup} from 'vue2-leaflet';
import L from 'leaflet';
//import Vue2LeafletRotatedMarker from 'vue2-leaflet-rotatedmarker'
//import KTLayoutQuickPanel from "@/assets/js/layout/extended/quick-panel.js";
//import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import { Datetime } from 'vue-datetime'
//import 'leaflet-plugin-trackplayback'
import '@flowide/leaflet-playback-plugin'
import Vue2LeafletRotatedMarker from 'vue2-leaflet-rotatedmarker'
import LoadingProcess from "@/view/pages/Loading-process.vue";

import moment from 'moment'; 
moment.locale(Cookie.GetCookie('language'));
moment.updateLocale('fr', {
    relativeTime : {
        past: function(input) {
          return input === "à l'instant"
            ? input
            : 'depuis '+input
        },
        s  : "à l'instant",
        future: "dans %s",
        ss : '%d secondes',
        m:  "une minute",
        mm: "%d minutes",
        h:  "une heure",
        hh: "%d heures",
        d:  "un jour",
        dd: "%d jours",
        M:  "un mois",
        MM: "%d mois",
        y:  "une année",
        yy: "%d années"
    }
});


export default {
	components: {
		LMap,
		datetime: Datetime,
		LPopup,
		LTileLayer,
		LPolyline,
		'v-rotated-marker': Vue2LeafletRotatedMarker,
		LoadingProcess,
    },
	data: () => ({
		mindata:{},
		polygonPopUp:{},
		AddRemouve : "+",
		ToleranceTime : "15",
		dateStart : "",
		Height: '1000px',
		url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
		attribution:
		'&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
		zoom: 8,
		center: [35.3264774,9.5552708],
		bounds: null,
		maps:null,
		itemsDataCarsMaps : [],
		geoJSON : {},
		geoJSONArray : [],
		//geoJSONTime : {},
		 icon_start: L.icon({
			iconUrl: 'media/icon/tripview_af.png',
			iconSize: [30, 42],
			iconAnchor: [18, 37]
		  }),
        icon_stop: L.icon({
			iconUrl: 'media/icon/tripview_bf.png',
			iconSize: [30, 42],
			iconAnchor: [0, 37]
		  }),
		
		totalRows: 1,
        currentPage: 1,
        perPage: 1000000000,
        pageOptions: [10, 50, 100,500],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
		fields: [],
		MapsOrTable : "table",
		moment: moment,
		loadingBar: false,
    }),
	destroyed() {
		//this.$destroy() 
		//clearInterval(this.TimeReloadDataSettings);
	},
	computed: {
		//new L.Playback(map, geoJSON, onPlaybackTimeChange, options);
		
	},
	
	async mounted() {
		this.polygonPopUp = [{
					type: "polyline",
					latlngs: [],
					color: "#ff00ff"
				  }];
		this.dateStart = new Date().toJSON();
		this.mindata = Cookie.GetCookie('mindata');
		for(var i in this.mindata){
			 this.mindata[i].color = "#"+Math.floor(Math.random()*16777215).toString(16);
		}
		console.log( this.mindata);
		this.clusterOptions = {}
		this.$nextTick(() => {
			for(var j in this.$refs.marker){
				this.$refs.marker[j].mapObject.openPopup();
			}
		});
		var body = document.body,
		html = document.documentElement;
		this.Height = Math.min( body.scrollHeight, body.offsetHeight,html.clientHeight, html.scrollHeight, html.offsetHeight );
		this.Height = this.Height < 500 ? 500 : this.Height;
		this.Height = (this.Height)+"px";
	
		axios.defaults.headers["Content-Type"] =  "application/json";
		axios.defaults.withCredentials = true;
		this.fields = [
			{ key: 'vehicle', label: this._i18n.messages[localStorage.getItem("language")].TEXT_ALL.VEHICLE, sortable: true, sortDirection: 'desc' },
			{ key: 'timestart', label: "Date du debut", sortable: true, sortDirection: 'desc' },
			{ key: 'timeend', label: "Date fin", sortable: true, sortDirection: 'desc' },
			{ key: 'timetrip', label: "Temps de trajet", sortable: true, sortDirection: 'desc' },
			{ key: 'show_details', label: "Maps", sortable: false, 'class': 'text-center' },
		]

		

	},
	created() {
		
    },
	methods: {
		ConvertTime (time,timeOnly){
            time = time/1000;
            if (time>=86400 && !timeOnly)
            {
                var jour = Math.floor(time/86400);
                var reste = time%86400;
                var heure =  Math.floor(reste/3600);
                // puis le nombre d'heures
                reste = reste%3600;
                var minute = Math.floor(reste/60);
                var seconde = reste%60;
                var result = jour+'j '+heure+'h '+minute+'m '+Math.floor(seconde)+'s';
            }
            else if (time>=3600)
            {
                heure =  Math.floor(time/3600);
                reste = time%3600;
                minute = Math.floor(reste/60);
                seconde = reste%60;
                result = heure+'h '+minute+'m '+Math.floor(seconde)+'s';
            }
            else if (time<3600 && time>=60)
            {
                minute = Math.floor(time/60);
                seconde = time%60;
                result = minute+'m '+Math.floor(seconde)+'s';
            }
            else if (time < 60) 
            {
                result = time+'s';
            }
            return result;	
        },
		ChangeVue(type) {
			this.MapsOrTable = type;
		},
		onFiltered(filteredItems) {
			this.totalRows = filteredItems.length
			this.currentPage = 1
		  },
		nodeClick (evt,index,time) {
			console.log(index);
			console.log(evt);
			console.log(time);
			console.log(evt.latlng.lat);
			console.log(evt.latlng.lng);
			console.log(this.$refs);
			var t = "";
			for (var i in time){
				console.log(time[i][0]);
				console.log(evt.latlng.lat);
				if(parseFloat(time[i][0]) == parseFloat(evt.latlng.lat) && parseFloat(time[i][0]) == parseFloat(evt.latlng.lng)){
					t=time[i].time;
				}
			}
			var data = this.GetDataCars(index);
			L.circleMarker([evt.latlng.lat, evt.latlng.lng]).addTo(this.$refs.maps.mapObject).bindPopup(data.name+" "+t).openPopup();
		 
		},
		zoomUpdated (zoom) {
		  this.zoom = zoom;
		},
		centerUpdated (center) {
		  this.center = center;
		},
		boundsUpdated (bounds) {
		  this.bounds = bounds;
		},
		GetColor (id) {
			for(var i in this.mindata){
				if(this.mindata[i].id == id)
					return this.mindata[i].color;
			}
			return "#000000";
		},
		GetDataCars (id) {
			for(var i in this.mindata){
				if(this.mindata[i].id == id)
					return this.mindata[i];
			}
			return "";
		},
		UpdateDataCars() {
			this.loadingBar = true;
			var ToleranceTimeValue={"15": 900000, "30": 1800000, "45": 2700000, "1": 3600000};
			var dateStart = new Date(this.dateStart).getTime();
			if(this.AddRemouve == '+'){
				var dateEnd = dateStart + ToleranceTimeValue[this.ToleranceTime];
			}else{
				dateEnd = dateStart;
				dateStart = dateEnd - ToleranceTimeValue[this.ToleranceTime];
			}
			var ids = [];
			for (var i in this.mindata){
				ids.push(this.mindata[i].id);
			}
			axios.post("/controleur/core/calldataV2.php?which=geojson&ids="+ids.join(",")+"&start="+dateStart+"&end="+dateEnd).then(async (response) => {
				this.geoJSON = {};
				this.geoJSONArray = [];
				//this.geoJSONTime = {};
				for (var i in response.data){
					if(response.data[i].geometry.coordinates.length != 0){
						for (var j in response.data[i].geometry.coordinates){
							response.data[i].geometry.coordinates[j] = response.data[i].geometry.coordinates[j].reverse();
							response.data[i].geometry.coordinates[j].time = response.data[i].properties.time[j];
						}
						//this.geoJSONTime[response.data[i].properties.id] = response.data[i].properties.time;
						this.geoJSON[response.data[i].properties.id] = response.data[i].geometry.coordinates;
						
						this.geoJSON[response.data[i].properties.id].color = this.GetColor(response.data[i].properties.id);
						this.geoJSON[response.data[i].properties.id].name = response.data[i].properties.name;
						//this.geoJSONArray[response.data[i].properties.id].name = response.data[i].properties.name;
						
						
						this.geoJSONArray.push({time:response.data[i].properties.time,coordinates:response.data[i].geometry.coordinates,color:this.geoJSON[response.data[i].properties.id].color,name :response.data[i].properties.name });
					}
				}
				this.$forceUpdate();
				this.polygonPopUp = [{
					type: "polyline",
					latlngs: this.geoJSON[response.data[i].properties.id],
					color: "#ff00ff"
				  }];
				this.loadingBar = false;
			}).catch((error) => {  
				if (error.response) {
					if (error.response.status == 500) {
						Cookie.DeleteCookie();
						this.$router.push('/login');
						this.loadingBar = false;
					}
				}
			});
			
		},
	},
}
</script>
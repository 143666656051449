<template>
	<div>
		<br>
        <div class="row">
			<!--begin::Aside-->
			<div class="col-3">
				<!--begin::Profile Card-->
				<div class="card card-custom ">
					<!--begin::Body-->
					<div class="card-body pt-4 pb-0">
						<h5 class="pb-5">Rapports Opérationnels : </h5>
						<div class="navi navi-bold navi-hover navi-active navi-link-rounded">
							<div class="navi-item mb-2" v-on:click="SelectRapport('general')">
								<div href="" :class="RapportSelected == 'general' ? 'navi-link py-4 active':'navi-link py-4'">
									<span class="navi-icon mr-2">
										<span class="svg-icon">
											<font-awesome-icon class="mr-2" :icon="['far', 'cars']"/>
										</span>
									</span>
									<span class="navi-text font-size-lg">{{$t('REPORTING.TITLE.REPORTING_GENERAL')}}</span>
								</div>
							</div>
							<div class="navi-item mb-2"  v-on:click="SelectRapport('trajet')">
								<div href="" :class="RapportSelected == 'trajet' ? 'navi-link py-4 active':'navi-link py-4'">
									<span class="navi-icon mr-2">
										<span class="svg-icon">
											<font-awesome-icon class="mr-2" :icon="['far', 'road']"/>
										</span>
									</span>
									<span class="navi-text font-size-lg">{{$t('REPORTING.TITLE.REPORTING_CAR_DETAIL')}}</span>
								</div>
							</div>
							<div class="navi-item mb-2"  v-on:click="SelectRapport('playback')">
								<div href="" :class="RapportSelected == 'playback' ? 'navi-link py-4 active':'navi-link py-4'">
									<span class="navi-icon mr-2">
										<span class="svg-icon">
											<font-awesome-icon class="mr-2" :icon="['far', 'map']"/>
										</span>
									</span>
									<span class="navi-text font-size-lg">Snapshot</span>
								</div>
							</div>
						</div>
						<!--end::Nav-->
					</div>
					<div class="card-body pb-0">
						<h5 class="pb-5">1er Démarrage</h5>
						<div class="navi navi-bold navi-hover navi-active navi-link-rounded">
							<div class="navi-item mb-2" v-on:click="SelectRapport('FirststartFleet')">
								<div href="" :class="RapportSelected == 'FirststartFleet' ? 'navi-link py-4 active':'navi-link py-4'">
									<span class="navi-icon mr-2">
										<span class="svg-icon">
											<font-awesome-icon class="mr-2" :icon="['far', 'cars']"/>
										</span>
									</span>
									<span class="navi-text font-size-lg">Flotte</span>
								</div>
							</div>
							<div class="navi-item mb-2"  v-on:click="SelectRapport('FirststartCar')">
								<div href="" :class="RapportSelected == 'FirststartCar' ? 'navi-link py-4 active':'navi-link py-4'">
									<span class="navi-icon mr-2">
										<span class="svg-icon">
											<font-awesome-icon class="mr-2" :icon="['far', 'road']"/>
										</span>
									</span>
									<span class="navi-text font-size-lg">Par véhicule</span>
								</div>
							</div>
						</div>
						<!--end::Nav-->
					</div>
					<!--end::Body-->
				</div>
				<!--end::Profile Card-->
			</div>
			<!--end::Aside-->
			<!--begin::Content-->
			<div class="col-9">
				<!--begin::Card-->
				<div class="card card-custom card-stretch p-4">
                    <RapportGeneral v-if="RapportSelected == 'general'"></RapportGeneral>
					<TrajetGeneral  v-if="RapportSelected == 'trajet'"></TrajetGeneral>
                    <PlaybackGeneral  v-if="RapportSelected == 'playback'"></PlaybackGeneral>
                    <FirststartFleet  v-if="RapportSelected == 'FirststartFleet'"></FirststartFleet>
                    <FirststartCar  v-if="RapportSelected == 'FirststartCar'"></FirststartCar>
				</div>
			</div>
			<!--end::Content-->
		</div>
		
	</div>
</template>

<script>
import RapportGeneral from "@/view/pages/reporting/General.vue";
import TrajetGeneral from "@/view/pages/reporting/Trajet.vue";
import PlaybackGeneral from "@/view/pages/reporting/Position.vue";
import FirststartFleet from "@/view/pages/reporting/FirststartFleet.vue";
import FirststartCar from "@/view/pages/reporting/FirststartCar.vue";


import { library } from '@fortawesome/fontawesome-svg-core'

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import { faCars as fasCars , faMap as fasMap , faRoad as fasRoad} from '@fortawesome/pro-solid-svg-icons'
import { faCars as farCars , faMap as farMap, faRoad as farRoad} from '@fortawesome/pro-regular-svg-icons'
library.add(fasCars,farCars,farMap,fasMap,fasRoad,farRoad)

export default {
	components: {
        RapportGeneral,TrajetGeneral,PlaybackGeneral,FirststartFleet,FirststartCar,
		'font-awesome-icon': FontAwesomeIcon,
    },
	data: () => ({
		RapportSelected:"general",
    }),
	destroyed() {
		
	},
	computed: {
		
	},
	
	mounted() {

	},
	created() {

    },
	methods: {
		SelectRapport(type){
            this.RapportSelected = type;
        }

	},
}
</script>